// Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
// for details on configuring this project to bundle and minify static web assets.

// Write your JavaScript code.
import 'jquery';
// Using the next two lines is like including partial view _ValidationScriptsPartial.cshtml
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'jquery-ajax-unobtrusive';

import '@popperjs/core';

import * as signalR from "@microsoft/signalr";

// import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

import '../scss/site.scss';
import '../css/site.css';

import '../images/veh.svg';
import '../images/info-16.png';
import '../images/info-20.png';

import "jquery-ui/ui/widgets/autocomplete";
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/menu.css';
import 'jquery-ui/themes/base/autocomplete.css';
import 'jquery-ui/themes/base/theme.css';


WozChecker = {
    initHub: function (id) {
        var connection = new signalR.HubConnectionBuilder().withUrl("/hubs/WozCheckerHub").build();
        connection.start().then(function () {
            connection.invoke("RegisterForReport", id);
        });
        connection.on("ReportFinished", () => {
            $("#download-link").removeClass('disabled');
            $("#download-spinner").prop('hidden', 'hidden');
            $("#download-text").text('Downloaden');
            connection.stop();
        });
       
        setTimeout(() => connection.stop(), 900000);
    },
    initAdresVelden: function (
        postcodeId,
        huisnummerId,
        toevoegingId,
        wozId,
        huisnummerActionUrl,
        toevoegingActionUrl,
        validateActionUrl,
        perceelId,
        oppervlakId,
        bouwjaarId,
        woningtypeId) {

        $('#wozwaardeTekstOnbekend').show();
        $('#wozwaardeTekstBekend').hide();
        var kenmerken = perceelId !== undefined;
        var adresIds = [postcodeId, huisnummerId, toevoegingId].join(",");
        $(wozId).on("change", function () {
            $(this).val($(this).val().replace('.', ''))
        });

        $(huisnummerId)
            .autocomplete({
                minLength: 0,
                source: function (request, response) {
                    var data = {
                        postcode: $(postcodeId).val(),
                        term: $(huisnummerId).val()
                    };
                    $.post(huisnummerActionUrl, data, function (result) {
                        response(result);
                    });
                }
            })
            .on("focus", function () {
                $(this).keydown();
            });

        $(toevoegingId)
            .autocomplete({
                minLength: 0,
                source: function (request, response) {
                    var data = {
                        postcode: $(postcodeId).val(),
                        huisnummer: $(huisnummerId).val(),
                        toevoeging: $(toevoegingId).val()
                    };
                    $.post(toevoegingActionUrl, data, function (result) {
                        response(result);
                    });
                }
            })
            .on("focus", function () {
                $(this).keydown();
            })
            .data('ui-autocomplete')._renderItem = function (ul, item) {
                var value = item.value === "" ? "(geen)" : item.value;

                return $("<li class=\"ui-menu-item\"></li>").data("item.autocomplete", item)
                    .append("<div class=\"ui-menu-item-wrapper\">" + value + "</div>")
                    .appendTo(ul);
            };

        $(postcodeId).on("change", function () {
            $(this).val($(this).val().replace(/\s/g, ''))
        });

        $(adresIds).on("change", function () {
            $(".validation-summary-errors").remove();
            updateAdres(
                $(postcodeId).val(),
                $(huisnummerId).val(),
                $(toevoegingId).val(),
                validateActionUrl,
                kenmerken,
                perceelId,
                oppervlakId,
                bouwjaarId,
                woningtypeId);
        });
        $(adresIds).on("autocompleteselect", function (event, ui) {
            $(".validation-summary-errors").remove();
            $(this).val(ui.item.value);
            $(this).trigger("change");
        });
    },
    initWoningtypeChange: function (woningtypeId, perceelId, postcodeId, huisnummerId, toevoegingId) {
        $(function () {
            $(woningtypeId).on("change", function () {
                var flat = $(this).val() > 4;

                $(perceelId).prop("readonly", flat);
                $(perceelId).attr("tabindex", flat ? -1 : 0);
            });

            if ($(postcodeId).val() !== "" && $(huisnummerId).val() !== "" && $(woningtypeId).val() == "0") {
                updateAdres(
                    $(postcodeId).val(),
                    $(huisnummerId).val(),
                    $(toevoegingId).val(),
                    kenmerken,
                    perceelId,
                    oppervlakId,
                    bouwjaarId,
                    woningtypeId);
            }

        });
    },
    goToDetails: function() {
        $("html,body").animate({
            scrollTop: $("#details").offset().top
        });

        $('#details [data-toggle="tooltip"]').tooltip();
    },
    initZelfDoenLatenDoenRegistration: function (zelfdoenActionUrl, latenDoenActionUrl) {
        $("#zelfDoen").on("touchstart mousedown", function () {
            zelfOfLatenDoen(zelfdoenActionUrl);
        });
        $("#latenDoen").on("touchstart mousedown", function () {
            zelfOfLatenDoen(latenDoenActionUrl);
        });
    },
    initTooltips: function () {
        $(function () {
            $('[data-bs-toggle="tooltip"]').tooltip();
        });
    },
    fixValidation: function () {
        $(function () {
            $('form').submit(function () {
                $(this).find("button[type='submit']").each(function (index, value) {
                    $(value).prop('disabled', true);
                })
            });

            var defaultRangeValidator = $.validator.methods.range;
            $.validator.methods.range = function (value, element, param) {
                if (element.type === 'checkbox') {
                    // if it's a checkbox return true if it is checked
                    return element.checked;
                } else {
                    // otherwise run the default validation function
                    return defaultRangeValidator.call(this, value, element, param);
                }
            }

            var form = $('form')
                , formData = $.data(form[0])
                , settings = formData.validator.settings
                // Store existing event handlers in local variables
                , oldErrorPlacement = settings.errorPlacement
                , oldSuccess = settings.success;

            settings.errorPlacement = function (label, element) {
                // Call old handler so it can update the HTML
                oldErrorPlacement(label, element);

                $("button[type='submit']:disabled").each(function (index, value) {
                    $(value).prop('disabled', false);
                });

                // Add Bootstrap classes to newly added elements
                label.addClass('invalid-feedback')
                label.parents('.mb-3').addClass('is-invalid');
                label.parents('.mb-3').find('.input-validation-error').addClass('is-invalid');
            };

            settings.success = function (label) {
                // Remove error class from <div class="form-group">, but don't worry about
                // validation error messages as the plugin is going to remove it anyway
                label.removeClass('invalid-feedback');
                label.parents('.mb-3').removeClass('is-invalid');
                label.parents('.mb-3').find('.input-validation-error').removeClass('is-invalid');

                $("button[type='submit']:disabled").each(function (index, value) {
                    $(value).prop('disabled', false);
                });

                // Call old handler to do rest of the work
                oldSuccess(label);
            };

            $('.field-validation-error').addClass('invalid-feedback');
            $('.validation-summary-errors')
                .addClass('invalid-feedback')
                .show()
                .find("ul li").contents().unwrap().unwrap();
        });
    },
    verlaatIFrame: function (action) {
        $(function () {
            window.open(action, "_parent");
        });
    }
}

export default WozChecker;

function updateAdres(
    postcode,
    huisnummer,
    toevoeging,
    actionUrl,
    kenmerken,
    perceelId,
    oppervlakId,
    bouwjaarId,
    woningtypeId) {

    if (isNullOrWhitespace(postcode) || isNullOrWhitespace(huisnummer)) {
        $("#woonplaats").val("");
        $("#straatnaam").val("");

        if (kenmerken) updateAdresMetKenmerken(perceelId, oppervlakId, bouwjaarId, woningtypeId);

        return;
    }
    var data = {
        postcode: postcode,
        huisnummer: huisnummer,
        toevoeging: toevoeging,
        kenmerken: kenmerken
    };
    $.post(actionUrl, data, function (result) {
        if (result.valid) {
            $("#woonplaats").val(result.woonplaats);
            $("#straatnaam").val(result.straat);
            $("#wozloketlink").attr("href", "https://www.wozwaardeloket.nl/index.jsp?locatie=" + postcode + "%20" + huisnummer + toevoeging);
            $('#wozwaarde').val(result.wozwaarde);
            if (result.wozwaarde) {
                $('#wozwaardeTekstOnbekend').hide();
                $('#wozwaardeTekstBekend').show();
            }
            else {
                $('#wozwaardeTekstOnbekend').show();
                $('#wozwaardeTekstBekend').hide();
            }
            if (kenmerken) updateAdresMetKenmerken(perceelId, oppervlakId, bouwjaarId, woningtypeId, result.perceel, result.oppervlak, result.bouwjaar, result.woningtype);
        }
        else {
            $("#woonplaats").val("");
            $("#straatnaam").val("");
            $("#wozwaarde").val("");
            $("#wozloketlink").attr("href", "https://www.wozwaardeloket.nl/index.jsp");
            $('#wozwaardeTekstOnbekend').show();
            $('#wozwaardeTekstBekend').hide();
            if (kenmerken) updateAdresMetKenmerken(perceelId, oppervlakId, bouwjaarId, woningtypeId);
        }
    });
}

function isNullOrWhitespace(value) { return !value || !value.trim(); }

function updateAdresMetKenmerken(
    perceelId,
    oppervlakId,
    bouwjaarId,
    woningtypeId,
    perceel,
    oppervlak,
    bouwjaar,
    woningtype) {
        $(perceelId).val(perceel || '');
        $(oppervlakId).val(oppervlak || '');
        $(bouwjaarId).val(bouwjaar || '');
        $(woningtypeId).val(woningtype || '');

        $(woningtypeId).trigger("change");
}

function zelfOfLatenDoen(actionUrl) {
    $.ajax({
        url: actionUrl,
        async: false
    });
}